<template>
  <div class="ShoppingCart">
    <div class="head">
      <div class="back">
        <img class="icon" src="../../assets/icon9.png" alt="" />
        <div @click="$router.go(-1)">返回</div>
      </div>
      <h2>AI文案-购物车</h2>
    </div>
    <div class="banner">
      <!-- 排序 -->
      <div class="list-title">
        <span class="infocompileIndex_btn"
          ><i
            class="introduce-checkBox-icon"
            :class="checked ? 'checkBox-checked' : ''"
            @click="handleCheckAllChange"
          ></i
          >全选</span
        >
        <div
          class="list-title-item"
          v-for="(item, index) in sortArr"
          :key="index"
          style="cursor: pointer"
          @click="sortClick(item)"
        >
          <div
            class="list-title-item-text"
            :class="item.colorSwitch == true ? 'fontColor' : ''"
          >
            {{ item.title }}
          </div>
          <i
            class="list-title-icon up"
            :class="item.sort == 'asc' ? 'up' : 'down'"
          ></i>
        </div>
      </div>
      <div class="content">
        <div class="item" v-for="(it, idx) in dataList" :key="idx">
          <div class="title">
            <span
              @click="handleChecked(it, idx)"
              class="introduce-checkBox-icon"
              :class="it.ischecked == true ? 'checkBox-checked' : ''"
            ></span>
            <div class="imgs">
              <img src="../../assets/story.png" alt="" />
            </div>
            <h3>{{ it.name }}</h3>
            <div class="delete" @click="deleteClcik(it)">
              <img src="../../assets/images/delete-active.png" alt="" />
            </div>
          </div>
          <div class="text">
            <div class="items" v-for="(item, idx) in it.esCases" :key="idx">
              <span
                @click="handleCheckeds(item, idx, it)"
                class="introduce-checkBox-icon"
                :class="item.ischeckeds == true ? 'checkBox-checked' : ''"
              ></span>
              <div class="idx">{{ idx + 1 }}</div>
              <div
                class="titl"
                :class="item.isSell == 1 ? 'cl' : ''"
                @click="caseDetails(item, it)"
              >
                {{ item.title }}
              </div>
              <div class="goshopp">
                {{ item.discountPrice ? item.discountPrice : item.price }}券
              </div>
              <div class="time">
                <img src="../../assets/times.png" alt="" />
                {{ item.createTime.split(' ')[0] }}
              </div>
              <div class="delete" @click="deletes(item)">
                <img src="../../assets/images/delete-active.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fooder">
      <!-- <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :total="100"
      >
      </el-pagination> -->
      <div class="shopp">
        已选择<span>{{ count }}</span
        >件商品
        <div class="zoj">
          总价: <span>{{ pric }}券</span>
        </div>
        <div class="fuk" @click="siblmien">支付</div>
      </div>
    </div>
    <!-- 检验成语券弹框 -->
    <ContinuationTips
      :Tipsmessage="currentTips"
      :isShow="isShow"
      :number="number"
      ref="ContinuationTips"
      @UpLevelAfter="isShow = false"
    />
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  submit,
  deletes,
  checkIdiomCoupon,
  caseDetail,
} from 'api/Continuation'
import { settings } from 'api/service'
import ContinuationTips from 'components/ContinuationTips'
import qs from 'qs'
export default {
  name: 'ShoppingCart',
  //import引入的组件需要注入到对象中才能使用
  components: { ContinuationTips },
  data() {
    //这里存放数据
    return {
      currentTips: '',
      // 显示次数不足弹框
      isShow: false,
      number: 0,
      // 勾选内容
      checkedList: [],
      checkedList1: [],
      // 一共多少钱
      priceList: [],
      pric: 0,
      count: 0,
      checked: false,
      checked1: false,
      dataList: [],
      num: 0,
      sortArr: [
        {
          title: '时间',
          sort: 'desc',
          id: 1,
          type: 'create_time',
          colorSwitch: true,
        },

        {
          title: '价格',
          type: 'price',
          sort: 'asc',
          id: 2,
        },
      ],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async submits(val) {
      let res1 = await submit(this.checkedList1)
      if (res1.code == 200) {
        this.$message.success(res1.data)
        this.getLists()
        this.count = 0
        this.pric = 0
      } else if (res1.code == 9009) {
        this.$message.error('已售出，购买失败！')
      }
    },
    // 查看详情
    async caseDetails(val, val1) {
      console.log(val, val1)
      if (val.id) {
        this.$router.push({
          path: '/Dilets',
          query: { id: 1, showlls: 1, vals: val },
        })
      } else {
        let res = {
          id: val1.id,

          obj: {
            textMd5: val.textMd5,
            labelId: val1.labelId,
          },
        }
        this.$router.push({
          path: '/Dilets',
          query: { id: 1, da: res, showlls: 2 },
        })
      }
    },
    // 全部删除
    deleteClcik(val) {
      this.$confirm('您确定要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let form = new FormData()
          val.esCases.forEach((item) => {
            form.append('options', item.textMd5)
          })

          let res = await deletes(form)
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.getLists()
          }
        })
        .catch(() => {})
    },
    // 删除购物车
    async deletes(val) {
      this.$confirm('您确定要删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const params = {
            options: val.textMd5,
          }
          let res = await deletes(qs.stringify(params))
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.getLists()
          }
        })
        .catch(() => {})
    },
    // 支付
    async siblmien() {
      if (this.checkedList1.length < 1) {
        this.$message.error('请选择需购买的内容')
        return
      }
      let res = await checkIdiomCoupon({
        type: 10,
        num: this.pric,
      })
      if (res.code == 200) {
        this.currentTips = ''
        let data = await settings()
        if (data.data.aiImg == 1) {
          this.isShow = false
          this.submits()
        } else {
          this.isShow = true
          // this.$bus.$emit('numbers', this.pric)
          this.$refs.ContinuationTips.numbers(this.pric)
        }
      } else if (res.code == 9008) {
        this.currentTips = res.message
        // this.$bus.$emit('numbers', '0.1997')
        this.$refs.ContinuationTips.numbers('0.1997')
        this.isShow = true
      }
      // let res = await submit(this.checkedList1)
      // if (res.code == 200) {
      //   this.$message.success('购买成功')
      //   this.getLists()
      // } else if (res.code == 9008) {
      //   this.$confirm(res.message, '提示', {
      //     confirmButtonText: '去购买',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   })
      //     .then(() => {
      //       // window.open("https://www.writemall.com/details/973");
      //       this.$router.push('/details/973')
      //     })
      //     .catch(() => {})
      // }
    },
    // 获取购物车数据
    async getLists() {
      let data = await getList()
      if (data.code == 200) {
        data.data.forEach((item) => {
          item.ischecked = false
          item.esCases.forEach((it) => {
            it.ischeckeds = false
          })
        })
        data.data.forEach((item) => {
          this.num += item?.esCases.length
        })
        window.localStorage.getItem('nums', this.num)
        this.dataList = data.data
      } else {
        this.dataList = []
      }
    },
    // 点击勾选

    handleChecked(val, i) {
      val.ischecked = !val.ischecked
      // if (val.ischecked) {
      //   this.checkedList1 = []
      //   return
      // }
      if (val.ischecked) {
        let isHas = false
        this.checkedList1.forEach((item, index) => {
          if (item.caseId == val.id) {
            isHas = true
            item.md5 = val.esCases.map((bar) => {
              bar.ischeckeds = val.ischecked
              return bar.textMd5
            })
            item.pase = val.esCases.map((bar) => {
              bar.ischeckeds = val.ischecked
              return bar.discountPrice ? bar.discountPrice : bar.price
            })
          }
        })

        if (!isHas) {
          // item.md5 =

          const paramsItem = {
            caseId: val.id,
            md5: val.esCases.map((bar) => {
              bar.ischeckeds = val.ischecked
              return bar.textMd5
            }),
            pase: val.esCases.map((bar) => {
              bar.ischeckeds = val.ischecked
              return bar.discountPrice ? bar.discountPrice : bar.price
            }),
          }
          this.checkedList1.push(paramsItem)
        }
      } else {
        this.checkedList1.forEach((item, index) => {
          if (item.caseId == val.id) {
            this.checkedList1.splice(index, 1)
          }
        })
        val.esCases.forEach((item) => {
          item.ischeckeds = val.ischecked
        })
      }
      // if (this.checkedList.indexOf(val.id) == -1) {
      //   this.checkedList.push(val.id);
      //   val.esCases.forEach((item) => {
      //     item.ischeckeds = val.ischecked;
      //     this.checkedList1.push(item.textMd5);
      // this.priceList.push(item.price);
      //   });
      // } else {
      //   val.esCases.forEach((item) => {
      //     item.ischeckeds = val.ischecked;
      //     let id = this.checkedList1.indexOf(item.textMd5);
      //     this.checkedList1.splice(id, val.esCases.length + 1);
      //     // this.priceList.splice(id, val.esCases.length + 1);
      //   });

      //   this.checkedList.splice(i, 1);
      // }
      this.checked = this.dataList.every((item) => {
        return item.ischecked
      })
      // let list = [];
      // this.dataList.forEach((item, i) => {
      //   list.push(item.ischecked);
      // });
      // if (list.indexOf(false) == -1) {
      //   this.checked = true;
      // } else {
      //   this.checked = false;
      // }
      let list = []
      const length = this.checkedList1.reduce((prev, item) => {
        list = item.pase
        prev = prev + item.md5.length
        return prev
      }, 0)
      this.pric = 0
      list.forEach((item) => {
        this.pric += item
      })

      this.count = length
    },
    handleCheckeds(val, i, obj) {
      val.ischeckeds = !val.ischeckeds
      // if (this.checkedList1.indexOf(val.textMd5) == -1) {
      //   this.checkedList1.push(val.textMd5);
      // } else {
      //   this.checkedList1.splice(i, 1);
      // }
      if (this.checkedList1.length == 0) {
        const paramsItem = {
          caseId: obj.id,
          md5: [val.textMd5],
          pase: [val.discountPrice ? val.discountPrice : val.price],
        }
        this.checkedList1.push(paramsItem)
      } else {
        let isHas = false
        this.checkedList1.forEach((item, index) => {
          if (item.caseId == obj.id) {
            isHas = true
            if (item.md5.indexOf(val.textMd5) == -1) {
              item.md5.push(val.textMd5)
              item.pase.push(val.discountPrice ? val.discountPrice : val.price)
            } else {
              item.md5.splice(item.md5.indexOf(val.textMd5), 1)
              item.pase.splice(
                item.pase.indexOf(
                  val.discountPrice ? val.discountPrice : val.price
                ),
                1
              )
              if (item.md5.length == 0) {
                this.checkedList1.splice(index, 1)
              }
            }
          }
        })

        if (!isHas) {
          const paramsItem = {
            caseId: obj.id,
            md5: [val.textMd5],
            pase: [val.discountPrice ? val.discountPrice : val.price],
          }
          this.checkedList1.push(paramsItem)
        }
      }

      this.checked = obj.esCases.every((item) => {
        return item.ischeckeds
      })
      obj.ischecked = obj.esCases.every((item) => {
        return item.ischeckeds
      })
      // let list = [];
      // obj.esCases.forEach((item, i) => {
      //   list.push(item.ischeckeds);
      // });
      // if (list.indexOf(false) == -1) {
      //   obj.ischecked = true;
      // } else {
      //   obj.ischecked = false;
      //   this.checked = false;
      // }
      // console.log(this.checkedList1);
      let list = []
      const length = this.checkedList1.reduce((prev, item) => {
        list = item.pase
        prev = prev + item.md5.length
        return prev
      }, 0)
      this.count = length
      this.pric = 0
      list.forEach((item) => {
        this.pric += item
      })
    },
    // 全选
    handleCheckAllChange() {
      this.checked = !this.checked
      this.checkedList1 = []
      if (this.checked) {
        this.dataList.forEach((item, i) => {
          item.ischecked = this.checked
          const paramsItem = {
            caseId: item.id,
            md5: [],
            pase: item.esCases.map((bar) => {
              bar.ischeckeds = item.ischecked
              return bar.discountPrice ? bar.discountPrice : bar.price
            }),
          }
          paramsItem.md5 = item.esCases.map((it) => {
            it.ischeckeds = this.checked
            return it.textMd5
          })
          this.checkedList1.push(paramsItem)
        })
      } else {
        this.dataList.forEach((item, i) => {
          item.ischecked = this.checked
          item.esCases.forEach((it) => {
            it.ischeckeds = this.checked
            this.checkedList1 = []
          })
        })
      }
      let list = []
      const length = this.checkedList1.reduce((prev, item) => {
        list = item.pase
        prev = prev + item.md5.length
        return prev
      }, 0)
      this.count = length
      this.pric = 0
      list.forEach((item) => {
        this.pric += item
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getLists()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.ShoppingCart {
  .head {
    display: flex;
    align-items: center;
    padding: 31px 58px;
  }
  .back {
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    color: #999999;
    .icon {
      width: 6px;
      height: 11px;
      margin-right: 11px;
    }
  }
  h2 {
    flex: 1;
    text-align: center;
    font-size: 16px;
  }
  .banner {
    .list-title {
      display: flex;
      align-items: center;
      padding-left: 62px;
      .adds {
        cursor: pointer;

        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #d6e5ff;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4587ff;
        &:hover {
          background-color: #f3f7ff;
        }
      }
      #LangeSelectNotes {
        margin-right: 25px;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        .list-title-item-text {
          font-size: 14px;

          font-weight: 500;
          color: #333333;
        }
        .list-title-icon {
          width: 9px;
          height: 13px;
          margin-left: 6px;
          margin: 21px 6px;
          @include backgroundGroup(
            '../../assets/images/goldenSentence/sortIcon.png'
          );
        }
        .up {
          @include backgroundGroup(
            '../../assets/images/goldenSentence/sortIcon-blue-up.png'
          );
        }
        .down {
          @include backgroundGroup(
            '../../assets/images/goldenSentence/sortIcon-blue.png'
          );
        }
        .fontColor {
          color: #ff6900;
        }
      }
      .infocompileIndex_btn {
        margin-right: 20px;
        display: flex;
        align-items: center;
        .introduce-checkBox-icon {
          cursor: pointer;
          display: inline-block;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url('../../assets/gouxu1.png');
          margin-right: 5px;
        }
        .checkBox-checked {
          background-image: url('../../assets/gouxu.png');
        }
      }
    }
    .content {
      width: 100%;
      height: 72vh;
      overflow-y: auto;
    }
    .items {
      position: relative;
      display: flex;
      align-items: center;
      padding: 13px 93px;
      font-size: 14px;
      .delete {
        position: absolute;
        right: 38px;
        top: calc(50% - 9px);
        cursor: pointer;
      }
      img {
        width: 16px;
        height: 15px;
        margin-right: 15px;
      }
      .idx {
        width: 30px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }

      .titl {
        cursor: pointer;
        font-weight: 400;
        color: #000000;
        width: 200px;
        flex: 1;
        overflow: hidden; //超出隐藏
        white-space: nowrap; //不折行
        text-overflow: ellipsis; //溢出显示省略号
      }
      .cl {
        color: #666;
      }
      .goshopp {
        cursor: pointer;
        width: 41px;
        color: #ff6900;

        margin-right: 20px;
      }

      .time {
        width: 200px;
        text-align: right;
        display: flex;
        align-items: center;
        padding-left: 5px;
        box-sizing: border-box;
        // align-items: center;
        color: #999999;
        img {
          width: 12px;
          height: 12px;
          margin-right: 2px;
        }
      }
      .go {
        cursor: pointer;
        position: absolute;
        right: 100px;
        margin-right: 20px;
        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #ffe1cd;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6500;
        &:hover {
          background-color: #fffaf3;
        }
      }
      .add {
        cursor: pointer;
        position: absolute;
        right: 0;
        padding: 11px 17px;
        background: #ffffff;
        border: 1px solid #d6e5ff;
        border-radius: 3px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4587ff;
        &:hover {
          background-color: #f3f7ff;
        }
      }
    }
    .item {
      .title {
        position: relative;
        width: 100%;
        height: 88px;
        background: #fffaf6;
        padding: 15px 65px 16px 59px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333333;
        .delete {
          position: absolute;
          right: 50px;
          top: calc(50% - 9px);
          cursor: pointer;
        }
        .imgs {
          width: 57px;
          height: 57px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          padding: 5px;
          box-sizing: border-box;
          margin-right: 14px;

          img {
            // height: 100%;
            width: 100%;
          }
        }
        .introduce-checkBox-icon {
          cursor: pointer;
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-bottom: 2px;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url('../../assets/gouxu1.png');
          margin-right: 5px;
        }
        .checkBox-checked {
          background-image: url('../../assets/gouxu.png');
        }
      }
    }
  }
  .fooder {
    width: 100%;
    padding-top: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .pagination {
      flex: 1;
      text-align: center;
      ::v-deep .active {
        background-color: #ff6900 !important;
        color: #fff;
      }
    }
    .shopp {
      flex: 1;
      display: flex;
      justify-content: right;
      align-items: center;
      font-size: 13px;
      color: #999999;
      span {
        font-size: 16px;
        color: #ff6900;
        margin: 0 5px;
      }
      .zoj {
        margin-left: 20px;
      }
      .fuk {
        cursor: pointer;
        width: 105px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ff6500;
        border-radius: 4px;
        color: #fff;
        margin: 0 30px;
      }
    }
  }
  .introduce-checkBox-icon {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/gouxu1.png');
    margin-right: 5px;
  }
  .checkBox-checked {
    background-image: url('../../assets/gouxu.png');
  }
}
</style>
